import axios from "axios";
import { serverConfig } from "./config";

const fetchMapsData = async (alertId) => {
  try {
    const response = await axios.get(
      `${serverConfig.REACT_APP_ROUTER_SERVICE_URL}/messenger/messages/${alertId}`
    );
    console.log("response.data", response.data.data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching location data:", error);
  }
};

export { fetchMapsData };
