// components/Shared/WhatsAppChatWidget.js
import { useState } from "react";
// import Image from "next/image";

const WhatsAppChatWidget = () => {
  const [isChatboxVisible, setIsChatboxVisible] = useState(false);

  const toggleChatbox = () => {
    setIsChatboxVisible(!isChatboxVisible);
  };

  const hideChatbox = () => {
    setIsChatboxVisible(false);
  };

  return (
    <div style={styles.chatWidget}>
      <div
        style={{
          ...styles.chatBox,
          ...(isChatboxVisible ? styles.chatBoxVisible : {}),
        }}
      >
        <div style={styles.chatBoxHeader}>
          {/* <div style={styles.avatarBox}>
            <Image
              src="../assets/images/apneck.png"
              alt="Chat Avatar"
              width={50}
              height={50}
            />
          </div> */}
          <div style={styles.infoBox}>
            <h4 style={styles.name}>SAFE SOS</h4>
            <span style={styles.answerTime}>
              Réponse généralement dans la journée
            </span>
          </div>
          <button style={styles.closeButton} onClick={hideChatbox}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
            </svg>
          </button>
        </div>
        <div style={styles.chatBoxBody}>
          <div style={styles.message}>
            <div style={styles.messageContent}>
              <p>Salut, comment puis-je vous aider ?</p>
            </div>
          </div>
        </div>

        <div style={styles.chatBoxFooter}>
          <a
            style={styles.btnWhatsApp}
            href="http://wa.me/96529200"
            target="_blank"
            rel="noopener noreferrer"
          >
            Démarrer le chat
          </a>
        </div>
      </div>
      <div style={styles.floatingButton} onClick={toggleChatbox}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 90 90">
          <path d="M90 43.841c0 24.213-19.779 43.841-44.182 43.841a44.256 44.256 0 01-21.357-5.455L0 90l7.975-23.522a43.38 43.38 0 01-6.34-22.637C1.635 19.628 21.416 0 45.818 0 70.223 0 90 19.628 90 43.841zM45.818 6.982c-20.484 0-37.146 16.535-37.146 36.859 0 8.065 2.629 15.534 7.076 21.61L11.107 79.14l14.275-4.537A37.122 37.122 0 0045.819 80.7c20.481 0 37.146-16.533 37.146-36.857S66.301 6.982 45.818 6.982zm22.311 46.956c-.273-.447-.994-.717-2.076-1.254-1.084-.537-6.41-3.138-7.4-3.495-.993-.358-1.717-.538-2.438.537-.721 1.076-2.797 3.495-3.43 4.212-.632.719-1.263.809-2.347.271-1.082-.537-4.571-1.673-8.708-5.333-3.219-2.848-5.393-6.364-6.025-7.441-.631-1.075-.066-1.656.475-2.191.488-.482 1.084-1.255 1.625-1.882.543-.628.723-1.075 1.082-1.793.363-.717.182-1.344-.09-1.883-.27-.537-2.438-5.825-3.34-7.977-.902-2.15-1.803-1.792-2.436-1.792-.631 0-1.354-.09-2.076-.09s-1.896.269-2.889 1.344c-.992 1.076-3.789 3.676-3.789 8.963 0 5.288 3.879 10.397 4.422 11.113.541.716 7.49 11.92 18.5 16.223C58.2 65.771 58.2 64.336 60.186 64.156c1.984-.179 6.406-2.599 7.312-5.107.9-2.512.9-4.663.631-5.111z"></path>
        </svg>
      </div>
    </div>
  );
};

export default WhatsAppChatWidget;

const styles = {
  chatWidget: {
    position: "fixed",
    bottom: "70px",
    right: "20px",
    zIndex: 1000,
  },
  chatBox: {
    display: "none",
    position: "fixed",
    bottom: "0",
    right: "0",
    width: "300px",
    height: "350px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    transition: "transform 0.3s ease",
    transform: "translateY(100%)",
  },
  chatBoxVisible: {
    display: "block",
    transform: "translateY(0)",
  },
  chatBoxHeader: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#004aad",
    padding: "15px",
    borderBottom: "1px solid #eee",
    position: "relative", // Allow positioning of the close button inside the header
  },
  closeButton: {
    position: "absolute", // Position it absolutely inside the header
    top: "10px", // Adjust top positioning as needed
    right: "10px", // Adjust right positioning as needed
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "20px",
    color: "#ff0000", // Red color for the close button
  },
  avatarBox: {
    width: "65px",
    height: "65px",
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: "10px",
  },
  infoBox: {
    flex: 1,
  },
  name: {
    color: "#fff",
    fontWeight: "bold",
  },
  answerTime: {
    fontSize: "12px",
    color: "#ffff",
  },

  chatBoxBody: {
    padding: "15px",
    background: "#e6ddd4",
  },

  // chatBoxBody: {
  //   padding: "15px",
  //   backgroundColor: "#e6ddd4",
  //   overflowY: "auto", // Allows scrolling if content overflows
  // },
  message: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
  },
  username: {
    fontWeight: "bold",
    color: "#333",
    marginBottom: "5px",
  },
  messageContent: {
    backgroundColor: "#dcf8c6", // WhatsApp-like green for received messages
    borderRadius: "8px",
    padding: "10px",
    maxWidth: "80%", // Limit the width of the message bubble
    marginBottom: "5px",
    alignSelf: "flex-start", // Align incoming messages to the left
  },
  messageOutgoing: {
    backgroundColor: "#fff", // White for outgoing messages
    borderRadius: "8px",
    padding: "10px",
    maxWidth: "80%",
    marginBottom: "5px",
    alignSelf: "flex-end", // Align outgoing messages to the right
  },
  chatBoxFooter: {
    padding: "15px",
    marginBottom: "100px",
    borderTop: "1px solid #eee",
  },
  btnWhatsApp: {
    display: "block",
    width: "100%",
    padding: "10px",
    textAlign: "center",
    backgroundColor: "#004aad",
    color: "#fff",
    textDecoration: "none",
    borderRadius: "4px",
    fontWeight: "bold",
  },
  floatingButton: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#004aad",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
};
