// eslint-disable-next-line no-unused-vars
import React, { useContext, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";

const header = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showMenu, setShowMenu] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <header className="navbar-middle sticky-top p-2 p-md-2 p-lg-2">
        <div className="container-xxl">
          <div className="row align-items-center m-0">
            <div className="col-md-2 d-flex justify-content-center">
              <button className="navbar-toggler d-md-none " type="button" onClick={toggleMenu}>
                <span className="navbar-toggler-icon">
                  {showMenu ? <AiOutlineClose /> : <AiOutlineMenu />}
                </span>
              </button>

              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid logo" />
              </Link>
            </div>

            <div className="col-md-10 row col-lg-10">
              <div className="col-md-6 m-auto">
                <div className="menu-links mt-2 d-none d-md-flex d-lg-flex">
                  <div className="ms-auto gap-3">
                    <NavLink
                      to="/"
                      className={location.pathname === "/" ? "active" : "not-active"}
                      onClick={toggleMenu}>
                      ACCUEIL
                    </NavLink>
                  </div>
                  <div className="ms-auto gap-3">
                    <NavLink
                      to="/about"
                      className={location.pathname === "/about" ? "active" : "not-active"}
                      onClick={toggleMenu}>
                      L&apos;APPLICATION
                    </NavLink>
                  </div>
                  <div className="ms-auto gap-3">
                    <NavLink
                      to="/contact"
                      className={location.pathname === "/contact" ? "active" : "not-active"}
                      onClick={toggleMenu}>
                      CONTACT
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            {showMenu && (
              <div className="col-md-10 d-md-none mt-3">
                <div className="menu-links mt-2">
                  <div className="mb-2">
                    <NavLink
                      className={location.pathname === "/" ? "active" : "not-active"}
                      to="/"
                      onClick={toggleMenu}>
                      ACCUEIL
                    </NavLink>
                  </div>
                  <div className="mb-2">
                    <NavLink
                      className={location.pathname === "/about" ? "active" : "not-active"}
                      to="/about"
                      onClick={toggleMenu}>
                      L&apos;APPLICATION
                    </NavLink>
                  </div>
                  <div className="mb-2">
                    <NavLink
                      className={location.pathname === "/contact" ? "active" : "not-active"}
                      to="/contact"
                      onClick={toggleMenu}>
                      CONTACT
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default header;
