// eslint-disable-next-line no-unused-vars
import React from "react";

const progressing = () => {
  return (
    <>
      <section className="cart">
        <div className="container-xxl p-5">
          <div className="container-xxl">
            <div className="row">
              <div className="text-center p-5 mb-4">
                <h2>Bientôt disponible sur l&apos;App Store!</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default progressing;
