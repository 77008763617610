import { useSearchParams } from "react-router-dom";
// import GoogleMaps from '@/components/Sos/GoogleMaps';
import GoogleMaps from "../../components/Sos/GoogleMaps";

export default function MapPage() {
  // eslint-disable-next-line no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();

  const alertId = searchParams.get("alert-id");
  console.log("alertId", alertId);

  return (
    <>
      <div className="mt-20">
        <GoogleMaps alertId={alertId} />
      </div>
    </>
  );
}
