/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import a6 from "../assets/images/about/a6.jpg";
import video from "../assets/images/about/1.mp4";
import WhatsAppChatWidget from "../components/WhatsAppChatWidget";

const about = () => {
  return (
    <>
      <section className="about-wrapper p-5 d-flex justify-content-center align-items-center">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <div className="shop-details text-center align-items-center">
                <h1>Application SAFE SOS</h1>
                {/* <p className="text-white fs-3">Get to know us more</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-app p-5">
        <div className="row">
          <div className="col-md-4">
            <img src={a6} alt="" className="img-fluid p-5" />
          </div>
          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
            <h1>Quis sommes-nous?</h1>
            <h2 className="card-text mb-3">
              Safe est une application dédiée à la sécurité personnelle en cas
              d&apos;urgence. Elle permet à toute personne en détresse
              d&apos;envoyer une alerte rapidement à ses proches pour demander
              de l&apos;aide. Conçue pour répondre aux besoins variés, Safe est
              particulièrement utile pour les femmes enceintes, les personnes
              âgées, les écoliers, et bien d&apos;autres. Grâce à une interface
              simple et intuitive, un seul geste suffit pour signaler une
              situation d&apos;urgence et garantir une réponse rapide de vos
              proches. Safe est votre compagnon de confiance pour rester
              protégé, où que vous soyez.
            </h2>
          </div>
        </div>
      </section>

      <section className="about-app p-5">
        <h2 className="fs-1 text-center mb-4">
          Téléchargez notre{" "}
          <Link
            to="https://play.google.com/store/apps/details?id=com.smartech.safe"
            target="_blank"
          >
            Application
          </Link>
        </h2>
        <div className="col-12 p-5 col-md-8 mx-auto">
          <video loop muted autoPlay src={video} className="w-100"></video>
        </div>
      </section>
      <WhatsAppChatWidget />
    </>
  );
};

export default about;
