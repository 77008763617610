/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import blog1 from "../assets/images/blog/b1.png";
import blog2 from "../assets/images/blog/b2.png";
import blog3 from "../assets/images/blog/b3.png";
import blog4 from "../assets/images/blog/b4.png";
import blog5 from "../assets/images/blog/b5.png";
import blog6 from "../assets/images/blog/b6.png";
import playstore from "../assets/images/pay/image.png";

const blog = () => {
  return (
    <>
      <section className="blog-wrapper p-5">
        <div className="container-xxl">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex flex-column justify-content-center ">
                <div
                  id="carouselExampleInterval"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="back-details">
                      <h1>Prévenez vos proches</h1>
                      <h1>d&apos;une situation critique en un clic</h1>
                      <h2>Vos proches peuvent vous localiser instantanément</h2>
                      <h2> et venir à votre secours</h2>

                      <Link
                        to="/about"
                        className="btn btn-primary mb-3"
                        id="button-link"
                      >
                        Lire plus
                      </Link>
                      <div className="mb-3">
                        <Link
                          to="https://play.google.com/store/apps/details?id=com.smartech.safe"
                          target="_blank"
                        >
                          <img
                            src={playstore}
                            alt=""
                            style={{ width: "150px", height: "auto" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blogs p-5">
        <div className="container-xxl container">
          <div className="row">
            <div className="d-flex flex-column align-items-center">
              <h1 className="mb-3">Nos services</h1>
              <p className="mb-4">QUI PEUT BÉNÉFICIER DE SAFE SOS ?</p>
            </div>
            <div className="col-12 col-md-6 p-3">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={blog4}
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">Femmes enceintes</h5>
                      <p className="card-text mb-3">
                        Les femmes enceintes peuvent rencontrer des
                        complications de santé à tout moment Si elles sont
                        seules à la maison ou en déplacement, il leur est
                        difficile de demander de l&apos;aide rapidement. Une
                        alerte via l&apos;application permettrait de prévenir
                        leurs proches pour qu&apos;ils viennent à leur secours,
                        surtout en cas d&apos;urgence imprévue.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-3">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={blog3}
                      alt=""
                      className="img-fluid rounded-start"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">Enfants/Écoliers</h5>
                      <p className="card-text mb-3">
                        Les enfants peuvent se retrouver en situation de danger
                        à l&apos;école ou sur le chemin du retour, comme être
                        victimes de harcèlement, se perdre ou subir un accident.
                        En utilisant cette application, ils pourraient
                        rapidement prévenir leurs parents et ces derniers
                        peuvent le localiser rapidement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-3">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={blog1}
                      alt=""
                      className="img-fluid rounded-start"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">Personnes handicapées</h5>
                      <p className="card-text mb-3">
                        Les personnes vivant avec un handicap physique ou mental
                        peuvent faire face à des situations où elles ont besoin
                        d&apos;assistance immédiate. Par exemple, une personne
                        en fauteuil roulant pourrait se retrouver coincée ou
                        incapable de se déplacer, et une personne sourde
                        pourrait avoir des difficultés à communiquer verbalement
                        une urgence. L&apos;application leur permettrait de
                        signaler instantanément un besoin d&apos;aide à leurs
                        proches.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-3">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={blog6}
                      alt=""
                      className="img-fluid rounded-start"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">Voyageurs</h5>
                      <p className="card-text mb-3">
                        Lorsqu&apos;une personne voyage seule, elle peut se
                        retrouver dans une situation dangereuse à
                        l&apos;étranger, comme un accident, une agression, ou
                        simplement se perdre dans un endroit inconnu.
                        L&apos;application permettrait d&apos;envoyer une alerte
                        géolocalisée à des proches, permettant une réaction
                        rapide même à distance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-3">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={blog2}
                      alt=""
                      className="img-fluid rounded-start"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">
                        Victimes de violence domestique
                      </h5>
                      <p className="card-text mb-3">
                        Pour les personnes vivant dans un environnement violent
                        ou menaçant, cette application pourrait offrir un moyen
                        discret et rapide d’envoyer une alerte à un proche ou à
                        un service d&apos;urgence. Cela peut être essentiel en
                        cas de danger imminent lorsque la victime ne peut pas
                        appeler à l&apos;aide directement par téléphone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-3">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={blog5}
                      alt=""
                      className="img-fluid rounded-start"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">Travailleurs de nuit</h5>
                      <p className="card-text mb-3">
                        Les personnes qui travaillent tard le soir ou la nuit,
                        comme les infirmiers, les employés de stations-service
                        ou les chauffeurs de taxi, sont souvent exposées à des
                        risques accrus de danger, que ce soit dans des zones peu
                        fréquentées ou face à des personnes malveillantes. Une
                        application d&apos;alerte leur permettrait de prévenir
                        rapidement leurs proches en cas d’incident ou de danger.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default blog;
