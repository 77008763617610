import React, { useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { fetchMapsData } from "../../api";
import PropTypes from "prop-types";
import { serverConfig } from "../../api/config";

const GoogleMaps = ({ alertId }) => {
  const mapRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [routeCoordinates, setRouteCoordinates] = useState([]);

  console.log("isLoading", isLoading);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchMapsData(alertId);
        console.log("data", data);
        const { gpsCoordinates, endTime } = data.contentJson;
        setRouteCoordinates(
          gpsCoordinates.map(({ latitude, longitude }) => ({
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          }))
        );
        if (endTime) {
          console.log("Alert ended, stopping fetch intervals.");
          clearInterval(intervalRef.current); // Clear interval
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const intervalRef = { current: null };

    getData();

    intervalRef.current = setInterval(getData, 60000);

    return () => clearInterval(intervalRef.current);
  }, [alertId]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: serverConfig.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
    });

    loader
      .load()
      .then(() => {
        if (mapRef.current) {
          const map = new google.maps.Map(mapRef.current, {
            zoom: 5,
            center: routeCoordinates.length
              ? routeCoordinates[0]
              : { lat: 39.60128890889341, lng: -9.069839810859907 },
          });

          if (routeCoordinates.length > 0) {
            const path = new google.maps.Polyline({
              path: routeCoordinates,
              geodesic: true,
              strokeColor: "#FF0000",
              strokeOpacity: 1.0,
              strokeWeight: 4,
            });
            path.setMap(map);

            routeCoordinates.forEach((coordinate, index) => {
              const marker = new google.maps.Marker({
                position: coordinate,
                map,
              });

              const infowindow = new google.maps.InfoWindow({
                content: `
                <div style="color: black; background-color: white;">
                  <h3>Informations du lieu</h3>
                  <p>Position : ${index + 1}</p>
                  <p>Latitude: ${coordinate.lat}</p>
                  <p>Longitude: ${coordinate.lng}</p>
                </div>
              `,
              });

              marker.addListener("click", () => {
                infowindow.open(map, marker);
              });
            });
          }
        } else {
          console.error("Map container (mapRef) is not available.");
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });
  }, [routeCoordinates]);

  return (
    <div
      style={{ height: "700px", width: "100%", marginTop: "80px", marginBottom: "70px" }}
      ref={mapRef}></div>
  );
};

GoogleMaps.propTypes = {
  alertId: PropTypes.string.isRequired,
};

export default GoogleMaps;
